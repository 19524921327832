import {Box, Typography} from "@mui/material";
import {ReactComponent as PlusIcon} from "../../assets/images/plus-green.svg";
import ClientNote from "./ClientNote";
import {useEffect, useState} from "react";
import NoteDialog from "./NoteDialog";
import {getClientNotes, saveClientNote} from "../../services/TraineeService";
import {getErrorMessage} from "../../enums/ErrorMessages";
import {useMessageContext} from "../../contexts/MessageContext";

const ClientPageNotes = ({client}) => {
  const [newNote, setNewNote] = useState(false);
  const [clientNotes, setClientNotes] = useState([]);

  const {notificationHandler} = useMessageContext();

  const [reload, setReload] = useState(false);

  useEffect(() => {
    getClientNotes(client.id).then(r => {
      setClientNotes(r.data);
    }).catch(e => {
      notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
    })
  }, [reload]);

  const handleNewNote = (note) => {
    const body = {
      traineeId: client.id,
      message: note.message
    }
    saveClientNote(body)
        .then(() => setNewNote(false))
        .then(() => setReload(prv => !prv))
        .then(() => notificationHandler.success("פתק נשמר בהצלחה"))
        .catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
        })
  }

  return (
      <>
        <NoteDialog initValue={{}} approve={handleNewNote} close={() => setNewNote(false)} isOpen={newNote} />
    <Box sx={{marginRight: "14px", marginLeft: "14px", marginTop: "28px",display: "flex", justifyContent: "space-between", alignItems: "center"}}>
    <Typography sx={{
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "23px",
      color: "#717784",
    }}>תאריך יצירה</Typography>
      <Box sx={{cursor: "pointer"}} onClick={() => setNewNote(true)}>
        <PlusIcon/>
      </Box>
    </Box>
        {clientNotes.length > 0 && clientNotes.sort((a, b) => b.createdDate - a.createdDate).map((note, i)  => {
          return <ClientNote reload={() => setReload(prv => !prv)} key={i} note={note}/>
        })}
      </>
  );
};

export default ClientPageNotes;
