import {useEffect, useState} from "react";
import {
  finishWorkout,
  getWorkout, rerunManipulation,
  resetWeek,
  updateProgram
} from "../../services/PlanSerice";
import {
  Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import WeekRow from "../WeekRow";
import {useMessageContext} from "../../contexts/MessageContext";
import {getErrorMessage} from "../../enums/ErrorMessages";

const WorkoutActiveTab = ({workoutIdx, editMode, planState, setEditMode, traineeId, setLoading}) => {

  const [workout, setWorkout] = useState();
  const [prvWorkout, setPrvWorkout] = useState();
  const [manipulationOpen, setManipulationOpen] = useState(false);
  const [reload, setReload] = useState(false);

  const {notificationHandler} = useMessageContext();

  const isAnyWorkoutNotHasFinishedOrWeekIsFinished = () => {
    const blocks = planState.plan.blocks.sort((a, b) => a.index > b.index ? 1 : -1);
    const curBlock = blocks.at(planState.blockIdx)

    const weeks = curBlock.weeks.sort((a, b) => a.index > b.index ? 1 : -1);
    const curWeek = weeks.at(planState.weekIdx);

    return curWeek.workouts.some(workout => workout.stateType === "COMPLETED") || curWeek.finished;
  }

  const isLastWeekFinished = () => {
    if (planState.blockIdx === 0 && planState.weekIdx === 0) {
      return true;
    }
    const blocks = planState.plan.blocks.sort((a, b) => a.index > b.index ? 1 : -1);


    if (planState.weekIdx === 0) {
      const prvBlock = blocks.at(planState.blockIdx -1);
      const prvWeek = prvBlock.weeks.sort((a, b) => a.index > b.index ? 1 : -1).at(prvBlock.weeks.length - 1);
      return prvWeek.finished;
    } else {
      const curBlock = blocks.at(planState.blockIdx);
      const prvWeek = curBlock.weeks.sort((a, b) => a.index > b.index ? 1 : -1).at(planState.weekIdx - 1);
      return prvWeek.finished;
    }
  }



  const getPrvWorkoutId = () => {
    const blocks = planState.plan.blocks.sort((a, b) => a.index > b.index ? 1 : -1);
    const curBlock = blocks.at(planState.blockIdx)

    const weeks = curBlock.weeks.sort((a, b) => a.index > b.index ? 1 : -1);
    const curWeek = weeks.at(planState.weekIdx);

    if (curWeek.index > 0) {
      return weeks.at(curWeek.index - 1)
          .workouts.sort((a, b) => a.index > b.index ? 1 : -1).at(workoutIdx).id;
    } else if (curBlock.index > 0) {
      return blocks.at(curBlock.index - 1).weeks
          .sort((a, b) => a.index > b.index ? 1 : -1)
          .at(blocks.at(curBlock.index - 1).weeks.length - 1).workouts.sort((a, b) => a.index > b.index ? 1 : -1).at(workoutIdx).id;
    }
  }


  useEffect(() => {
    setLoading(true);
    const workouts = planState.plan.blocks.sort((a, b) => a.index > b.index ? 1 : -1)
        .at(planState.blockIdx).weeks.sort((a, b) => a.index > b.index ? 1 : -1)
        .at(planState.weekIdx)
        .workouts.sort((a, b) => a.index > b.index ? 1 : -1);

    const workoutId = workouts.at(workoutIdx).id;

    const prvWorkout = getPrvWorkoutId();

    getWorkout(workoutId).then(res => {
      setWorkout(res.data)
      setLoading(false);
    })


    if (prvWorkout != null) {
      getWorkout(prvWorkout).then(res => {
        setPrvWorkout(res.data);
      })
    } else {
      setPrvWorkout(null);
    }

  }, [planState, workoutIdx, reload]);

  const handleFinishWorkout = (workoutId) => {
    finishWorkout(workoutId)
        .then(() => setReload(prv => !prv))
        .then(() => setEditMode(false))
        .then(() => notificationHandler.success("אימון סויים בהצלחה"))
  };

  const handleResetWeek = (weekId) => {
    resetWeek(weekId)
        .then(() => setReload(prv => !prv))
        .then(() => setEditMode(false))
        .then(() => notificationHandler.success("שבוע אופס בהצלחה"))
  }

  const handleRerunManipulation = () => {
    const blocks = planState.plan.blocks.sort((a, b) => a.index > b.index ? 1 : -1);
    const curBlock = blocks.at(planState.blockIdx)

    const weeks = curBlock.weeks.sort((a, b) => a.index > b.index ? 1 : -1);
    const curWeek = weeks.at(planState.weekIdx);

    let weekId = null;
    if (planState.blockIdx === 0 && planState.weekIdx === 0) {
      weekId = curWeek.id;
    } else if (planState.weekIdx === 0){
      weekId = blocks.at(planState.blockIdx -1).weeks.sort((a, b) => a.index > b.index ? 1 : -1)
          .at(blocks.at(planState.blockIdx -1).weeks.length -1).id
    } else {
      weekId = weeks.at(planState.weekIdx - 1).id;
    }

    rerunManipulation(traineeId, planState.plan.id, weekId) // weekId of the prvWeek
        .then(() => setReload(prv => !prv))
        .then(() => setEditMode(false))
        .then(() => notificationHandler.success("שבוע חושב מחדש"))
  }

  const addExercise = () => {
    setWorkout(prv => {
      let newWorkout = {...prv};

      let programExercises = newWorkout.programExercises.sort((a, b) => a.index > b.index ? 1 : -1);

      programExercises.push({
        index: newWorkout.programExercises.length,
        manipulation: "A1"
      })

      newWorkout.programExercises = programExercises;

      return newWorkout;
    })
  }

  const handleSave = () => {
    setLoading(true);
    updateProgram({...workout, traineeId: traineeId})
        .then(() => setReload(prv => !prv))
        .then(() => setEditMode(false))
        .then(() => notificationHandler.success("תוכנית נשמרה בהצלחה"))
        .then(() => setLoading(false))
        .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)))
        .catch(() => setLoading(false));
  }

  return (
      <>
      {workout && <>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
                  textAlign: "center",
                  fontSize: "16px",
                  padding: "8px", color: "#fff",
                  backgroundColor: "#57837E"}}>מ״ס
                </TableCell>
                {manipulationOpen &&
                    <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
                      textAlign: "center",
                      fontSize: "16px",
                      padding: "8px", color: "#fff",
                      backgroundColor: "#57837E"}}>מניפולציה
                    </TableCell>}
                <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
                  textAlign: "center",
                  fontSize: "16px",
                  padding: "8px", color: "#fff",
                  backgroundColor: "#57837E"}}>
                  <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    {!manipulationOpen ?
                        <KeyboardArrowRightIcon onClick={() => setManipulationOpen(true)} sx={{cursor: "pointer", fontSize: "16px", ml: "2px", mt: "2px", "&:hover" : {
                            color: "#B9D9D5",
                          }}}/> :
                        <KeyboardArrowLeftIcon onClick={() => setManipulationOpen(false)} sx={{cursor: "pointer", fontSize: "16px", ml: "2px", mt: "2px", "&:hover" : {
                            color: "#B9D9D5",
                          },}}/>}
                    <Typography>שריר</Typography>
                  </Box>
                </TableCell>
                <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
                  textAlign: "center",
                  fontSize: "16px",
                  padding: "8px", color: "#fff",
                  backgroundColor: "#57837E"}}>תרגיל
                </TableCell>
                <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
                  textAlign: "center",
                  fontSize: "16px",
                  padding: "8px", color: "#fff",
                  backgroundColor: "#57837E"}}>סטים
                </TableCell>
                <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
                  textAlign: "center",
                  fontSize: "16px",
                  padding: "8px", color: "#fff",
                  backgroundColor: "#57837E"}}>מנוחה
                </TableCell>
                <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
                  textAlign: "center",
                  fontSize: "16px",
                  padding: "8px", color: "#fff",
                  backgroundColor: "#57837E"}}>הערות
                </TableCell>
                <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
                  textAlign: "center",
                  fontSize: "16px",
                  padding: "8px", color: "#fff",
                  backgroundColor: "#57837E"}}>סדרה
                </TableCell>
                <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
                  textAlign: "center",
                  fontSize: "16px",
                  padding: "8px", color: "#fff",
                  backgroundColor: "#57837E"}}>קצב תנועה
                </TableCell>
                <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
                  textAlign: "center",
                  fontSize: "16px",
                  padding: "8px", color: "#fff",
                  backgroundColor: "#57837E"}}>בוצע
                </TableCell>
                {editMode && <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
                  textAlign: "center",
                  fontSize: "16px",
                  padding: "8px", color: "#fff",
                  backgroundColor: "#57837E"}}>מחק
                </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {workout && workout.programExercises?.sort((a, b) => a.index > b.index ? 1 : -1).map((exercise, index) => {
                return <WeekRow setEditWorkout={setWorkout}
                                manipulationOpen={manipulationOpen}
                                prvWorkout={prvWorkout}
                                prvWorkoutIdx={workoutIdx}
                                editMode={editMode}
                                exercise={exercise}
                                numberOfElements={workout.programExercises.length}/>
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{display: "flex", justifyContent: "space-between"}}>
          <Box>
            <Button
                disabled={!planState.plan.active}
                onClick={() => editMode ? addExercise() : setEditMode(true)}
                sx={{mt:"10px", mr: "10px"}} variant={"contained"}>
              {!!editMode ? "הוסף תרגיל +" : "ערוך"}
            </Button>
            {workout?.stateType !== "COMPLETED" && <Button
                disabled={!planState.plan.active}
                onClick={() => handleFinishWorkout(workout.id)}
                sx={{mt:"10px", mr: "10px"}} variant={"contained"}>
              סיים אימון
            </Button>}
            <Button
                disabled={!planState.plan.active}
                onClick={() => handleResetWeek(planState.plan.blocks.
                sort((a, b) => a.index > b.index ? 1 : -1)
                    .at(planState.blockIdx)
                    .weeks
                    .sort((a, b) => a.index > b.index ? 1 : -1)
                    .at(planState.weekIdx).id)}
                sx={{mt:"10px", mr: "10px"}} variant={"contained"}>
              אפס שבוע
            </Button>
            <Button
                disabled={!planState.plan.active ||
                    isAnyWorkoutNotHasFinishedOrWeekIsFinished() ||
                    !isLastWeekFinished()}
                onClick={() => handleRerunManipulation(planState.plan.blocks.
                sort((a, b) => a.index > b.index ? 1 : -1)
                    .at(planState.blockIdx)
                    .weeks
                    .sort((a, b) => a.index > b.index ? 1 : -1)
                    .at(planState.weekIdx).id)}
                sx={{mt:"10px", mr: "10px"}} variant={"contained"}>
              הפעל מניפולציה
            </Button>
            {editMode &&
                <Button
                    onClick={() => {
                      setReload(prv => !prv);
                      setEditMode(false);
                    }}
                    sx={{mt:"10px", mr: "10px", color: "#F16063"}} variant={"text"}>
                  בטל
                </Button>}
          </Box>

          {editMode && <Button
              disabled={planState.plan.blocks.
                  sort((a, b) => a.index > b.index ? 1 : -1)
                  .at(planState.blockIdx)
                  .weeks
                  .sort((a, b) => a.index > b.index ? 1 : -1)
                  .at(planState.weekIdx).finished}
              onClick={handleSave}
              sx={{mt:"10px", mr: "10px"}} variant={"contained"}>
            שמור ושלח
          </Button>}
        </Box>
      </>}
      </>
  );
};

export default WorkoutActiveTab;
